export const ABOUT_US = {
  we_make: {
    ENGLISH: "We Make a",
    BENGALI: "পাত্র-পাত্রী খুজে নিন",
  },
  perfect_couple: {
    ENGLISH: "Perfect Couple",
    BENGALI: "সহজে এবং কম সময়ে",
  },
  details: {
    ENGLISH:
      "Just like a perfect match in a relationship, our couple shoes are designed to complement each other flawlessly. Step into the world of comfort and style together with our exclusive range, perfect for those who believe in walking hand-in-hand in every journey of life.",
    BENGALI:
      "আমাদের অ্যাপটি ব্যবহার করে মাত্র কয়েকটি ক্লিকেই খুঁজে নিন আপনার পছন্দের পাত্র-পাত্রী দেশের যে কোন জেলা থেকে। আর তাই, আপনার মূল্যবান সময় নষ্ট না করে সহজেই বেছে নিন আপনার মনের মানুষটিকে। আপনার উপযুক্ত জীবনসঙ্গী খুঁজে পেতে সর্বোচ্চ সহযোগিতা নিয়ে আমরা আছি আপনার পাশে।",
  },
  know_more: {
    ENGLISH: "Know more about us",
    BENGALI: "আমাদের সম্পর্কে আরও জানুন",
  },
};
