import { IconArrowUpLeft, IconArrowUpRight } from "@tabler/icons-react";
import React from "react";
import image from "../../../assets/images/png version.png";
import { ABOUT_US } from "../../../constants/translate/AboutUs";
import { getUserLanguage } from "../../../utils/commonFunctions/selectLanguage";

const AboutUs = () => {
  return (
    <div id="about" className="relative">
      <section className=" text-gray-600 body-font mt-2">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="flex items-center gap-5 lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <div className="flex flex-col gap-10 items-end justify-start"></div>
            <div className="flex flex-col gap-10 ">
              <img src={image} alt="people" />
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="lg:flex-grow ml-4 md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"
          >
            {/* <h6 className="text-lg text-rose-600 font-medium text-center mb-2">ABOUT US</h6> */}
            <h1 className="title-font sm:text-5xl  font-bold text-3xl mb-4  text-gray-900">
              {ABOUT_US.we_make[getUserLanguage()]}
              <br className="hidden lg:inline-block" />
              <span className="text-rose-500">
                {" "}
                {ABOUT_US.perfect_couple[getUserLanguage()]}
              </span>
            </h1>
            <div className="h-1 w-56 bg-rose-400 mb-3 rounded-full  mb-5"></div>
            <p className="mb-8 leading-relaxed">
              {ABOUT_US.details[getUserLanguage()]}
            </p>
            <div className="flex justify-center">
              <button
                className="inline-flex text-white  border-0 py-2 px-6 h-12  w-auto bg-rose-500 
          rounded-br-[6rem] rounded-tr-[5rem] rounded-tl-[5rem] 
      rounded-bl-lg text-lg text-center flex justify-center items-center hover:bg-rose-600"
              >
                {ABOUT_US.know_more[getUserLanguage()]} &nbsp;
                <IconArrowUpRight />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
