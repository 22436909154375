import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoSvg from "../../../assets/images/shohozshadi-logo.svg";
import logo3 from "../../../assets/images/logo3.svg";
import { HashLink } from "react-router-hash-link";
import WhatsappButton from "../whatsappButton/WhatsappButton";

const NewNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleLinkClick = () => {
    if (isOpen) {
      toggleNavbar();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="sticky top-0 z-50">
      <nav
        className={
          !isScrolled
            ? "bg-gradient-to-b from-rose-50 via-rose-50 to-rose-50"
            : "bg-white navbar-with-box-shadow"
        } // Fixed height for the navbar
      >
        <div className="max-w-screen-2xl flex items-center  justify-between ">
          <div className="flex items-center w-52 h-12 px-4 m-4">
            <img src={logo3} style={{ width: "100%" }} alt="MM Logo" />
          </div>
          <button
            onClick={toggleNavbar}
            type="button"
            className="inline-flex items-center mx-4 w-10 h-10 justify-center text-sm text-pink-600 rounded-lg md:hidden hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-pink-400"
            aria-controls="navbar-default"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isOpen ? "fixed top-16 left-0 right-0 z-50" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            {/* Updated navigation links */}
            <ul className="font-medium flex flex-col p-4 md:m-1 border border-pink-200 rounded-lg bg-white/95 md:flex-row md:space-x-8 rtl:space-x-reverse md:border-0 md:bg-transparent ">
              <li>
                <Link
                  to={"/"}
                  className="block  md:pt-2 py-2 px-3 text-lg text-[#F5004F] rounded transition-transform duration-300 hover:scale-105  md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                >
                  Home
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={handleLinkClick}
                  to="/pricing"
                  className="block md:pt-2 py-2 px-3 text-lg text-[#F5004F] rounded transition-transform duration-300 hover:scale-105 md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                >
                  Pricing
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={handleLinkClick}
                  className="block  md:pt-2 py-2 px-3 text-lg text-[#F5004F] rounded transition-transform duration-300 hover:scale-105  md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                  to={"/download"}
                >
                  Download Now
                </Link>
              </li>

              <li>
                <HashLink
                  smooth
                  to={"#about"}
                  className="block md:border-2 px-3 py-2 text-lg md:rounded-br-lg md:rounded-tr-[5rem] md:rounded-tl-[5rem] md:rounded-bl-[6rem] md:border-rose-500 
      md:text-gray-900 md:bg-transparent text-[#F5004F] md:bg-rose-500 hover:shadow-xl "
                >
                  Know Us
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to={"#contact"}
                  className="block md:bg-rose-500 px-3 py-2 text-lg  mr-4 md:rounded-br-[4rem] md:rounded-tr-[5rem] md:rounded-tl-[5rem] md:rounded-bl-lg 
      md:text-white text-[#F5004F] hover:shadow-xl"
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NewNavbar;
