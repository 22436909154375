export const getUserCountry = (): string => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = timeZone.split("/")[1];
    return country ? country.toUpperCase() : "Unknown";
  } catch (error) {
    console.error("Error determining user country:", error);
    return "Unknown";
  }
};
