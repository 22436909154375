import React, { useState } from 'react';
import { mobImg1, mobImg2 } from '../../../../assets';

const AppGallery = () => {
  const images = [
    mobImg1,
    mobImg2,
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Go to the previous image (move backward)
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  // Go to the next image (move forward)
  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative  max-w-5xl mt-8">
      {/* Images Wrapper */}
      <div className="overflow-hidden">
        <div
          className="flex transition-transform ease-out duration-500 sm:pl-10 overflow-y-scroll hidescroll"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
           <iframe className='w-96 h-96 object-cover mx-2 rounded-xl shadow-xl shadow-gray-400 mb-10' 
           src="https://www.youtube.com/embed/ONP7P_54IBQ" 
           title="First Love, First Pain (Playlist)"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  ></iframe>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="w-66 h-96 object-cover mx-2 rounded-xl shadow-xl shadow-gray-400 mb-10"
            />
          ))}
        </div>
      </div>

        </div>
  );
};

export default AppGallery;
