export const HERO = {
  most_truested: {
    ENGLISH: "Most Trusted",
    BENGALI: "সহজে খুজুন পাত্র পাত্রী সহজ ",
  },
  matrimony_service: {
    ENGLISH: "Matrimony Service",
    BENGALI: "হোক বিয়ে শাদি",
  },
  details: {
    ENGLISH:
      "A trustworthy guide to discover your true love forever with the best compatibility. Ensuring you all meaningful and authentic connections.",
    BENGALI:
      "উপযুক্ত পাত্র-পাত্রী খুঁজে পেতে Shohoz Shadi আপনার জন্য নিয়ে এসেছে সবচেয়ে নির্ভরযোগ্য, দ্রুত, এবং সহজ সমাধান। আমাদের অত্যাধুনিক অ্যাপে ফ্রিতে  রেজিস্ট্রেশনের মাধ্যমে আপনি পেয়ে যাবেন আপনার পছন্দমত জীবনসঙ্গী। তাই, আর দেরি কেন? এখনই নিচের ডাউনলোড বোতামে ক্লিক করে আমাদের অ্যাপটি ডাউনলোড করুন এবং মুহূর্তেই খুঁজে নিন আপনার জীবনসঙ্গী।",
  },
  download_app: {
    ENGLISH: "Download APP",
    BENGALI: "অ্যাপ ডাউনলোড করুন",
  },
  perfect_match: {
    ENGLISH: "Perfect Match",
    BENGALI: "পারফেক্ট ম্যাচ",
  },
  customer_support: {
    ENGLISH: "Customer Support",
    BENGALI: "গ্রাহক সমর্থন",
  },
  verified_profiles: {
    ENGLISH: "Verified Profiles",
    BENGALI: "যাচাইকৃত প্রোফাইল",
  },
};
