import { HERO } from "../../../constants/translate/Hero";
import { getUserLanguage } from "../../../utils/commonFunctions/selectLanguage";
import "./DownloadButton.css";
import DownloadIcon from "./DownloadIcon";
const DownloadButton = ({ handleOpenDialog }: { handleOpenDialog: any }) => {
  return (
    <button className="download-button" onClick={handleOpenDialog}>
      <DownloadIcon className="mysvg" />
      <span className="download-text">
        {HERO.download_app[getUserLanguage()]}
        <DownloadIcon className="download" />
      </span>
    </button>
  );
};

export default DownloadButton;
