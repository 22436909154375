import React from "react";
import { mb } from "../../../assets";
import DownloadButton from "../downloadButton/DownloadButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import couple from "../../../assets/images/vector-couple.png";
import support from "../../../assets/images/icons8-customer-care-96.png";
import verifiedUser from "../../../assets/images/icons8-checked-user-male-96.png";
import DownloadApkModal from "../downloadApkModal/DownloadApkModal";
import { HashLink } from "react-router-hash-link";
import { HERO } from "../../../constants/translate/Hero";
import { getUserLanguage } from "../../../utils/commonFunctions/selectLanguage";

const Hero = () => {
  return (
    <div
      id="home"
      className="flex flex-wrap justify-around  md: bg-gradient-to-b from-rose-50 via-rose-50 to-transparent"
    >
      <div className="lg:w-4/6 sm:w-full px-4">
        <h1 className="heading font-bold text-3xl md:text-7xl text-center px-6 text-gray-900 leading-snug md:leading-snug">
          {HERO.most_truested[getUserLanguage()]}{" "}
          <span className="text-rose-500 font-extrabold mt-2">
            {HERO.matrimony_service[getUserLanguage()]}
          </span>
        </h1>
        <p className="text-center px-4 md:text-center text-sm md:text-2xl text-gray-800 mt-4">
          {HERO.details[getUserLanguage()]}
        </p>
        <div className="mt-4 ms-2 px-4 flex items-center justify-center mb-4">
          <DownloadApkModal />
        </div>

        {/*feature section hn*/}
        <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          <div className=" flex items-center justify-center md:w-68 h-24  px-4  bg-transparent ">
            <div className="block rounded-full w-16 h-16 items-center m-4 ">
              <div className="">
                <lottie-player
                  src="https://lottie.host/ea17ef7b-50c1-4096-9203-df63922c1274/cmxbWHiuL7.json"
                  background="transparent"
                  speed="2"
                  style={{ width: "60px", height: "60px" }}
                  direction="1"
                  mode="bounce"
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
            <div className=" w-44">
              <h2 className="text-rose-500 font-bold text-3xl">1K+</h2>
              <h2 className="font-semibold text-lg md:text-[16px] text-gray-900">
                {HERO.perfect_match[getUserLanguage()]}
              </h2>
              {/* <p className="text-gray-500 text-sm">Find your soulmate</p> */}
            </div>
          </div>

          <div className=" flex items-center justify-center md:w-68 h-24  px-4">
            <div className="block rounded-full w-16 h-16 items-center m-4">
              <div className="">
                {/* <img src={support} style={{width: "100%"}}/> */}
                <lottie-player
                  src="https://lottie.host/87e1ef8a-a4ae-4e8f-9aba-050953df6992/USZyEUK7l4.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "60px", height: "60px" }}
                  direction="1"
                  mode="bounce"
                  autoplay
                  loop
                ></lottie-player>
              </div>
            </div>
            <div className="w-44">
              <h2 className="text-rose-500 font-bold text-3xl">24/7</h2>
              <h2 className="font-semibold text-lg text-gray-900 md:text-[16px]">
                {HERO.customer_support[getUserLanguage()]}
              </h2>
              {/* <p className="text-gray-500 text-sm">Find your soulmate</p> */}
            </div>
          </div>

          <div className=" flex items-center justify-center md:w-68 h-24  px-4">
            <div className="block rounded-full w-16 h-16 items-center m-4">
              <div className="">
                {/* <img src={verifiedUser} style={{width: "90%"}}/> */}
                <lottie-player
                  src="https://lottie.host/fee903a0-c70d-459a-b82b-2135b7bb44b1/QVwMbBsZ0x.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "60px", height: "60px" }}
                  direction="1"
                  mode="normal"
                  autoplay
                  loop
                ></lottie-player>
              </div>
            </div>
            <div className=" w-44">
              <h2 className="text-rose-500 font-bold text-3xl">3K+</h2>
              <h2 className="font-semibold text-lg md:text-[16px] text-gray-900">
                {HERO.verified_profiles[getUserLanguage()]}
              </h2>
              {/* <p className="text-gray-500 text-sm">Ensuring authenticity</p> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="max-w-1/2 md:-mt-14 -mt-16 h-fit"
      >
        <img src={mb} style={{ width: "45rem" }} />
      </div> */}
    </div>
  );
};

export default Hero;
