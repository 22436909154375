import { Button, TextField } from '@mui/material'
import React from 'react'

const DeleteAccount = () => {
  return (
    <div className='bg-gradient-to-b from-rose-50 via-rose-50 to-transparent items-center justify-center'>
        <h2 className='font-semibold text-center text-2xl text-gray-800'>Do you want to delete your account?</h2>
        <div className='bg-transparent flex items-center justify-center '>
        <form className='bg-white border border-gray-300 rounded-md w-1/3 px-6 py-6 h-auto flex flex-col items-center justify-center mt-10'>
            <div className=' mb-4 w-full flex justify-between items-center'>
                <label>Enter Password:</label>
                <TextField variant='outlined' type='password'/>
            </div>
            <div className=' mb-4 w-full flex justify-between items-center '>
                <label>Re-enter Password:</label>
                <TextField variant='outlined' type='password'/>
            </div>
            <div className=' w-full flex justify-end items-end gap-2'>
            <Button variant='outlined' className='cancel-button'>
                Cancel
               </Button>
               <Button variant='contained' className='delete-button'>
                Delete
               </Button>
              
            </div>
        </form>
        </div>
       
    </div>
  )
}

export default DeleteAccount
