import React, { useState } from "react";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="" data-aos="fade-up" data-aos-duration="1000">
      <section className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mb-16">
            <h6 className="text-lg text-rose-600 font-medium text-center">
              FAQs
            </h6>
            <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
              Frequently asked questions
            </h2>
            <div className="h-1 w-56 bg-rose-400 mb-3 rounded-full mx-auto mt-5"></div>
          </div>

          <div
            className="accordion-group px-4 sm:px-6 md:px-8 lg:px-20"
            data-accordion="default-accordion"
          >
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`accordion py-8 px-4 sm:px-6 border-b border-solid border-gray-200 transition-all duration-500 rounded-2xl hover:bg-rose-50 ${
                  activeIndex === index ? "accordion-active:bg-indigo-50" : ""
                }`}
              >
                <button
                  className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-900 w-full transition duration-500 text-left hover:text-rose-600 accordion-active:text-indigo-600"
                  aria-controls={`basic-collapse-${index}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <h5>{item.question}</h5>
                  <svg
                    className={`text-gray-500 transition duration-500 group-hover:text-rose-600 ${
                      activeIndex === index
                        ? "accordion-active:text-rose-600 rotate-180"
                        : ""
                    }`}
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                      stroke="currentColor"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
                <div
                  id={`basic-collapse-${index}`}
                  className={`accordion-content w-full px-0 overflow-hidden transition-max-height duration-500 ease-in-out ${
                    activeIndex === index ? "max-h-96" : "max-h-0"
                  }`}
                  aria-labelledby={`basic-heading-${index}`}
                >
                  <p className="text-base text-gray-900 leading-6">
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

const faqData = [
  {
    question: "How do I update my billing information?",
    answer:
      "To update your billing information, go to your account settings and follow the instructions provided.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      'To contact customer support, look for a "Contact us" or "Help" button or link on the website or platform.',
  },
  {
    question: "How do I update my profile information?",
    answer:
      "To update your profile information, navigate to the profile section in your account settings.",
  },
  {
    question: "How do I find my purchase history?",
    answer:
      "To find your purchase history, go to the order history section in your account settings.",
  },
];

export default Faq;
