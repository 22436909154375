import { IconArrowUpRight } from "@tabler/icons-react";
import { FEATURES } from "../../../constants/translate/Features";
import { getUserLanguage } from "../../../utils/commonFunctions/selectLanguage";

const FeatureCard = ({ img, title, desc }: any) => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center p-4 md:p-8">
      <img
        className="h-full w-full md:h-66 md:w-[40rem] object-cover"
        src={img}
        alt=""
      />
      <div className="lg:flex-grow ml-0 md:ml-5 mt-4 md:mt-0 md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-3xl font-bold text-2xl mb-4 text-gray-900">
          {title}
        </h1>
        <div className="h-1 w-24 md:w-56 bg-rose-400 mb-3 rounded-full"></div>
        <p className="mb-6 md:mb-8 leading-relaxed text-sm md:text-base">
          {FEATURES.select_partner_details[getUserLanguage()]}
        </p>
        <div className="flex justify-center md:justify-start">
          <button
            className="inline-flex text-white border-0 py-2 px-4 md:px-6 h-12 w-auto bg-rose-500 
            rounded-br-[6rem] rounded-tr-[5rem] rounded-tl-[5rem] rounded-bl-lg text-sm md:text-lg text-center flex justify-center items-center hover:bg-rose-600"
          >
            {FEATURES.know_more[getUserLanguage()]} &nbsp;
            <IconArrowUpRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
