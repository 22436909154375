import { chat } from "../../assets";

export const FEATURES = {
  select_your_partner: {
    ENGLISH: "Select your partner",
    BENGALI: "আপনার জীবনসঙ্গী চয়ন করুন",
  },
  chat_with_anyone: {
    ENGLISH: "Chat with anyone",
    BENGALI: "কাউকে চ্যাট করুন",
  },
  whats_in_it_for_you: {
    ENGLISH: "What's in it for you",
    BENGALI: "আপনার জন্য আকর্ষণীয় বৈশিষ্ট্যগুলি",
  },
  features: {
    ENGLISH: "FEATURES",
    BENGALI: "বৈশিষ্ট্য",
  },
  features_description: {
    ENGLISH: "FEATURES_DESCRIPTION",
    BENGALI:
      "আমাদের সফল অ্যাপটিতে রয়েছে আকর্ষণীয় বৈশিষ্ট্যসমূহ যা আপনার প্রিয়জন খোঁজার কাজটিকে আরো বেশি সহজ এবং আনন্দদায়ক করে তুলবে। বিশেষ বৈশিষ্ট্য গুলির মধ্যে রয়েছে-",
  },
  features_point1: {
    ENGLISH: "",
    BENGALI:
      "ফ্রি রেজিস্ট্রেশনঃ আপনার সঠিক তথ্য যেমন ইমেইল, ফোন নম্বর, এবং একটি পাসওয়ার্ড দিয়ে আমাদের অ্যাপটিতে মিনিটের মধ্যেই করতে পারবেন ফ্রি রেজিস্ট্রেশন।",
  },
  features_point2: {
    ENGLISH: "",
    BENGALI:
      "নিজে লেখা প্রফাইলঃ আপনি আপনার প্রোফাইলটি আপনার পছন্দমত তথ্য যেমন নাম, ছবি, বায়ো, শখ, ইত্যাদি সংযুক্ত করে মনের মত সাজাতে পারেন।",
  },
  features_point3: {
    ENGLISH: "",
    BENGALI:
      "২-স্টেপ ভেরিফিকেশনঃ প্রোফাইলে লগইনের সময় আমাদের অ্যাপের ২-স্টেপ ভেরিফিকেশন সিস্টেম আপনার ব্যক্তিগত তথ্যের সম্পূর্ন সুরক্ষা প্রদান করে। ",
  },
  features_point4: {
    ENGLISH: "",
    BENGALI:
      "ফিল্টার সার্চ সিস্টেমঃ ফিল্টার সার্চ সিস্টেমের মাধ্যমে আপনি আপনার পছন্দের মানুষটিকে তার অবস্থান, বয়স, পেশা, পছন্দ, ইত্যাদি ক্যাটাগরিতে সহজেই খুঁজে পাবেন।",
  },
  features_point5: {
    ENGLISH: "",
    BENGALI:
      "চ্যাট সিস্টেমঃ আপনার পছন্দের মানুষটির সাথে তাৎক্ষণিক যোগাযোগ করতে আপনার জন্য রয়েছে চ্যাট সিস্টেম। একে অপরের সাথে সহযোগিতা বা বন্ধুত্ত্ব সম্পর্ক গড়ে তুলতে আমাদের অ্যাপের চ্যাট সিস্টেমটি আপনাকে সাহায্য করবে।",
  },

  know_more: {
    ENGLISH: "Know More About Us",
    BENGALI: "আরও জানুন আমাদের সম্পর্কে",
  },
  select_partner_details: {
    ENGLISH:
      "Distance can be beautiful when you are waiting for Allah to unite you both on the right path. If Allah has written something for you, it will happen. The time may be different, the journey may be different. But, it will be yours.... Trust in Allah's timing and wait for the halal way.",
    BENGALI:
      "জীবনসঙ্গী নির্বাচনের ক্ষেত্রে একে অন্যের প্রতি পরিপূর্ণ বিশ্বাস এবং সমঝোতা তৈরি হওয়া অনেক বেশি গুরুত্বপূর্ণ। পাশাপাশি, পরস্পরের প্রতি সম্মান, শ্রদ্ধাবোধ, পারিবারিক, এবং সামাজিক মূল্যবোধকে গুরুত্ব দিন। এমন একজন জীবনসঙ্গী নির্বাচন করুন যিনি জীবনের সকল পরিস্থিতিতে আপনার পাশে ছায়া হয়ে থাকবে। এবং, সবকিছুর জন্য মহান আল্লাহর উপর আস্থা রাখুন। ",
  },
  chat_with_anyone_details: {
    ENGLISH:
      "Use our app to find Muslim matchmakers in 64 districts of Bangladesh We will help you find the perfect life partner in just a few clicks InshaAllah",
    BENGALI:
      "আমাদের অ্যাপটি আপনার পাত্র-পাত্রী খোঁজার কাজটিকে অনেকটাই সহজ করে তুলবে। খুব সহজেই আপনার পছন্দের মানুষটিকে খুঁজে পেতে আজই ফ্রি রেজিস্ট্রেশন করুন আমাদের অত্যাধুনিক অ্যাপটিতে এবং দেশের বিভিন্ন জেলার পাত্র-পাত্রীর প্রোফাইল যাচাইয়ের মাধ্যমে একজনকে পছন্দ করুন এবং তার সম্পর্কে আরো জানতে তাকে চ্যাট করুন। ",
  },
};
