import React from "react";
import FeatureCard from "../featureCrad/FeatureCard";
import { chat, date } from "../../../assets";
import FeatureSIdeCard from "../featureCrad/FeatureSIdeCard";
import { FEATURES } from "../../../constants/translate/Features";
import { getUserLanguage } from "../../../utils/commonFunctions/selectLanguage";
import image from "../../../assets/images/img 2.png";

const Features = () => (
  <>
    <div className="relative md:mt-32">
      <div className="who_we_sec">
        <div className="mb-1 ">
          <h6 className="text-lg text-rose-600 font-medium text-center ">
            {FEATURES.features[getUserLanguage()]}
          </h6>
          <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
            {FEATURES.whats_in_it_for_you[getUserLanguage()]}
          </h2>
          <div className="h-1 w-56 bg-rose-400 mb-3 rounded-full mx-auto mt-5"></div>
          {/* <div className="flex justify-center items-center gap-7 px-6">
            {FEATURES.features_description[getUserLanguage()]}
          </div>
          <p className="mt-4">{FEATURES.features_point1[getUserLanguage()]}</p> */}
        </div>
        <div className="flex justify-center items-center gap-7 px-6">
          <FeatureCard
            img={image}
            title={FEATURES.select_your_partner[getUserLanguage()]}
          />
        </div>
      </div>
    </div>
    <div className="relative -mt-32">
      <div className="pt-32">
        <div className="flex justify-center items-center gap-7 px-6">
          <FeatureSIdeCard
            img={chat}
            title={FEATURES.chat_with_anyone[getUserLanguage()]}
          />
        </div>
      </div>
    </div>
  </>
);

export default Features;
