import React, { useRef, useState } from 'react';

const VideoPlayer = () => {
  const videoRef:any = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className=' flex items-center flex-col mb-20'>
     <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-2xl sm:text-2xl lg:text-3xl font-bold mb-3"
          >
            Steps to download our app
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="h-1 w-24 sm:w-40 lg:w-56 bg-rose-400 mb-3 rounded-full"
          ></div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-sm sm:text-base text-center lg:text-lg font-base px-4 sm:px-6 lg:px-10 mb-8 lg:mb-16"
          >
            See our tutorial video if you face any problem installing our app in your phone.
          </div>
    <div
      style={{ position: 'relative', width: '100%', maxWidth: '750px', height:"100%",maxHeight:'700px', margin: 'auto',marginTop:-10 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className='rounded-xl'
      >
<iframe className='w-full h-[400px] object-cover  sm:rounded-xl shadow-xl shadow-gray-400 ' 
           src="https://www.youtube.com/embed/ONP7P_54IBQ" 
           title="First Love, First Pain (Playlist)"  
           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  ></iframe>
      {/* Radial gradient overlay */}
      <div
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle, rgba(255, 255, 255, 0) 20%, rgba(0, 0, 0, 0.5) 60%)',
            pointerEvents: 'none', // Allows clicks to pass through to video
        }}
        className='sm:rounded-xl shadow-xl'
        ></div>

     
    </div>
      </div>
  );
};

export default VideoPlayer;
