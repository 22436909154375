import { useState } from "react";
import { appLogo, logo } from "../../../../assets";

const DownloadContainer = () => {
  // const handleDownload = () => {
  //   // const link = "https://shohozshadi.com/shohozshadi-app.apk";
  //   // window.location.href = link;
  //   // return;
  //   const userAgent = navigator.userAgent || navigator.vendor;

  //   // Check if the user is using Facebook or Messenger in-app browser
  //   if (userAgent.includes("FBAN") || userAgent.includes("FBAV")) {
  //     // Attempt to open the link directly in the default browser
  //     window.open(
  //       `googlechrome://navigate?url=https://shohozshadi.com/shohozshadi-app.apk`,
  //       "_blank",
  //       "noopener,noreferrer"
  //     );
  //     return;
  //   }

  //   const link = document.createElement("a");
  //   link.href = `https://shohozshadi.com/shohozshadi-app.apk`;
  //   link.setAttribute("download", "shohozshadi-app.apk");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const handleDownload = () => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "https://shohozshadi.com/shohozshadi-app.apk", true);
    xhr.responseType = "blob";

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setDownloadProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const url = window.URL.createObjectURL(xhr.response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shohozshadi-app.apk");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setDownloadProgress(0);
      }
    };

    xhr.send();
  };

  return (
    <main className="sm:p-6 pt-10">
      <div className="bg-white rounded-lg sm:p-6 flex justify-start sm:justify-between">
        <div className=" sm:hidden">
          <img
            src={appLogo}
            alt="WhatsApp Icon"
            className="w-16 h-16 rounded-2xl shadow-2xl mx-5"
          />
        </div>
        <div className="sm:pl-10 sm:pr-20">
          <h1 className="text-2xl sm:text-6xl font-semibold mb-1 sm:mb-5">
            Shohoz Shadi
          </h1>
          <p className="text-[#ed3385] font-medium text-lg mb-1 sm:mb-5">
            Shohozshadi
          </p>
          {/* <div className="flex -ml-20 sm:-ml-0 items-center sm:space-x-4 my-10">
            <div className="text-lg font-semibold sm:border-r-2 pr-5">
              4.3 ⭐
            </div>
            <div className=" sm:border-r-2 pr-5">198M reviews</div>
            <div className=" sm:border-r-2 pr-5">5B+ downloads</div>
            <div className="px-2 py-1 bg-gray-100 text-sm rounded pr-5 hidden sm:inline ">
              Mature 17+
            </div>
          </div> */}
          {downloadProgress ? (
            <div className="w-full hidden sm:inline bg-gray-200 rounded-full dark:bg-gray-700">
              <div
                className="bg-[#ed3385] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: `${downloadProgress}%` }}
              >
                {" "}
                {Math.ceil(downloadProgress)}%
              </div>
            </div>
          ) : (
            <button
              onClick={handleDownload}
              className="bg-[#ed3385] hidden sm:inline text-gray-100 mt-10
      rounded-lg px-6 py-4 ml-20 sm:-ml-0  "
            >
              Download Now
            </button>
          )}
        </div>

        <div className="hidden sm:inline">
          <img
            src={appLogo}
            alt="WhatsApp Icon"
            className="w-56 h-56 mr-20 rounded-[3rem] shadow-2xl "
          />
        </div>
      </div>
      {downloadProgress ? (
        <div className="px-5 mt-5">
          <div className="w-full bg-pink-200 rounded-full">
            <div
              className="bg-[#ed3385] text-xs font-medium text-black text-center p-1 leading-none rounded-full"
              style={{ width: `${downloadProgress}%` }}
            >
              <p>{Math.ceil(downloadProgress)}%</p>{" "}
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={handleDownload}
          className="bg-[#ed3385] w-[90%] rounded-full mx-5 sm:hidden text-gray-100 hover:bg-green-700 px-6 py-3 mt-5 sm:-ml-0  "
        >
          Download Now
        </button>
      )}
    </main>
  );
};

export default DownloadContainer;
