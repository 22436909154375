import React from "react";
import DownloadContainer from "./downloadContainer/DownloadContainer";
import AppGallery from "./AppGallery/AppGallery";
import AppSuppport from "../../shared/appsupport/AppSuppport";

const DownloadPage = () => {
  return (
    <div>
      <div className="min-h-screen bg-white">
        <DownloadContainer />
        <div className="sm:flex justify-start">
          <AppGallery />
          <AppSuppport />
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
