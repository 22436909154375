import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DownloadButton from "../downloadButton/DownloadButton";
import { appLogo } from "../../../assets";
import { useNavigate } from "react-router-dom";

const DownloadApkModal: React.FC = () => {
  const navigate = useNavigate();

  const handleDownloadPageNavigate = () => {
    navigate("/download");
  };

  return (
    <div>
      <DownloadButton handleOpenDialog={handleDownloadPageNavigate} />
      {/* <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle style={{ backgroundColor: "#f5f5f5" }}>
          Download App
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={appLogo}
              alt="Shohoz Shadi"
              style={{
                marginRight: "16px",
                width: 40,
                borderRadius: "20%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
            <DialogContentText
              style={{
                fontWeight: "semibold",
                color: "black",
                fontSize: "1.2rem",
                fontFamily: "'Google Sans', sans-serif",
              }}
            >
              Shohoz Shadi
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#f5f5f5" }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownload} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default DownloadApkModal;
