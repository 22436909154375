import React from 'react';
import { FiGlobe, FiPhone } from 'react-icons/fi';  // World icon for websites
import { FiLock } from 'react-icons/fi';   // Lock icon for privacy policy
import { FiMail } from 'react-icons/fi';   // Mail icon for contact mail
import { FiMapPin } from 'react-icons/fi'; // Map pin icon for address

// Sample app data with name, email, type (to make the icon dynamic), and address
const appList = [
  {
    name: 'Phone',
    phone: ['+880 1700952906', '+880 1700952907'],
    type: 'phone',
  },
  {
    name: 'Contact Mail',
    email: 'info@shohozshadi.com',
    type: 'email',
  },
  {
    name: 'Privacy Policy',
    url: 'https://shohozshadi.com/privacy',
    type: 'privacy',
  },
  {
    name: 'Company Address',
    address: 'Dhaka, Bangladesh',
    type: 'address',
  },
];

const AppSupport = () => {
  return (
    <div className='p-5'>
      <div className="text-2xl font-medium text-gray-800 mb-4">
        App Support
      </div>
      <div className="">
        {appList.map((app, index) => (
          <div key={index} className='hover:bg-gray-200 rounded-xl px-5 ml-5 py-2'>
            {/* Dynamic Icon and Link/Email/Address */}
            <div className="flex items-center space-x-2 text-md text-blue-600">
              {/* Display different icon based on the type */}
              {app.type === 'phone' ? (
                <FiPhone className="w-4 h-4 text-gray-800" />
              ) : app.type === 'privacy' ? (
                <FiLock className="w-4 h-4 text-gray-800" />
              ) : app.type === 'email' ? (
                <FiMail className="w-4 h-4 text-gray-800" />
              ) : (
                <FiMapPin className="w-4 h-4 text-gray-800" />
              )}
              {app.url ? (
                <a href={app.url} target="_blank" rel="noopener noreferrer" className="text-gray-800">
                  {app.name}
                </a>
              ) : (
                <span className="text-gray-800">
                  {app.name}
                </span>
              )}
            </div>
            {/* Contact Email or Address */}
            {app.email && (
              <div className="text-sm text-gray-600">
                {app.email}
              </div>
            )}
            {app.address && (
              <div className="text-sm text-gray-600">
                {app.address}
              </div>
            )}
             {app.phone && (
              <div className="text-sm text-gray-600">
                {app.phone.map(phone => {
                  return (
                    <a href={`tel:${phone}`}>{phone} || </a>
                  )
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AppSupport;
