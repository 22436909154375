import React, { useEffect } from "react";
import Hero from "../../shared/hero/Hero";
import Stats from "../stats/Stats";
import AboutUs from "../../shared/aboutUs/AboutUs";
import Faq from "../../shared/faq/Faq";
import Footer from "../../shared/footer/Footer";
import Features from "../../shared/featues/Features";
import Newsletter from "../../shared/newsletter/Newsletter";
import Steps from "../../shared/steps/Steps";
import AOS from "aos";
import "aos/dist/aos.css";
import Pricing from "../pricing/Pricing";
import Contact from "../contact/Contact";
import VideoPlayer from "../../shared/videoPlayer/VideoPlayer";
const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Hero />
      <AboutUs />
      <VideoPlayer/>
      {/* <Stats /> */}
      <Features />
      {/* <Pricing/> */}
      {/* <Newsletter /> */}
      <Contact/>
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
