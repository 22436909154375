import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Pricing from "./components/pages/pricing/Pricing";
import NewNavbar from "./components/shared/navbar/NewNavbar";
import Contact from "./components/pages/contact/Contact";
import DeleteAccount from "./components/pages/deleteAccount/DeleteAccount";
import DownloadPage from "./components/pages/downloadPage/DownloadPage";
import WhatsappButton from "./components/shared/whatsappButton/WhatsappButton";
import PrivacyPolicy from "./components/pages/privacypolicy/PrivacyPolicy";

const App = () => {
  const updateUserCount = async () => {
    const apiCalled = localStorage.getItem("userCountApiCalled");

    if (!apiCalled) {
      try {
        await fetch(
          "https://d3be5gm8hnmvue.cloudfront.net/api/v1/user/update-user-count",
          {
            method: "GET",
          }
        );
        localStorage.setItem("userCountApiCalled", "true");
      } catch (error) {
        console.error("Error updating user count:", error);
      }
    }
  };
  useEffect(() => {
    updateUserCount();
  }, []);

  return (
    <BrowserRouter>
      <NewNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Routes>
      <WhatsappButton />
    </BrowserRouter>
  );
};

export default App;
