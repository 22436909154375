const Pricing = () => (
    <div>
        <section className="px-6 xl:px-0 bg-gradient-to-b from-rose-50 via-rose-50 to-transparent">
            <div className="mx-auto container">
                <div className="flex flex-col lg:items-center justify-center w-full">
                    <h1 className="font-semibold text-gray-800 text-3xl md:text-4xl">The Right Plan for you</h1>
                    <p className="mt-2.5 lg:w-1/2 lg:text-center text-2xl">We have several plans to showcase you. Get everything you need</p>
                </div>
                <div className="flex items-center justify-center w-full">
                    <div className="pt-14">
                        <div className="container mx-auto">

                            <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                                <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                                        <h4 className="text-2xl text-[#F5004F] font-semibold pb-8">One Message</h4>
                                        <ul className="flex flex-col mb-6">
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
                                                <p className="text-gray-800 text-base font-normal">1 Person chat access</p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
                                                <p className="text-gray-800 text-base font-normal">Unlimited Choice</p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                <p className="text-gray-400 text-base font-normal">365 days validity</p>
                                            </li>

                                        </ul>
                                        <p className="text-base text-indigo-700 relative pl-3">
                                            <span className="font-light text-lg">BDT </span>
                                            <span className="text-2xl font-semibold">150</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                                    <div className="py-5 px-4 bg-[#F5004F] border border-gray-200 shadow rounded-lg text-left">
                                        <h4 className="text-2xl text-white font-semibold pb-8">Pro</h4>
                                        <ul className="flex flex-col mb-6">
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
                                                <p className="text-white text-base font-normal">5 Person Chat access</p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
                                                <p className="text-white text-base font-normal">Unlimited </p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
                                                <p className="text-white text-base font-normal">30 Days Validity</p>
                                            </li>

                                        </ul>
                                        <p className="text-base text-white relative pl-3">
                                            <span className="font-light text-lg">BDT </span>
                                            <span className="text-2xl font-semibold">500</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                                    <div className="py-4 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                                        <h4 className="text-2xl text-[#F5004F] font-semibold pb-8">Enterprise</h4>
                                        <ul className="flex flex-col mb-6">
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
                                                <p className="text-gray-800 text-base font-normal">10 person chat access</p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
                                                <p className="text-gray-800 text-base font-normal">5 months validity</p>
                                            </li>
                                            <li className="flex items-center mb-2.5">
                                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
                                                <p className="text-gray-800 text-base font-normal">Unlimited Choice</p>
                                            </li>

                                        </ul>
                                        <p className="text-base text-indigo-700 relative pl-3">
                                            <span className="font-light text-lg">BDT </span>
                                            <span className="text-2xl font-semibold">1000</span>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: "",
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>

        <style>
            {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
        </style>
    </div>
);
export default Pricing;
